import Cookies from 'js-cookie';

import {CreateRecognition, CreateRecognitionResult, GetPaginatedRecognitionData, RecognitionData, RecognitionNotification, TokenTypeDisplayData} from '../../../src/types/recognition';
import { GetManageRewardsData, ManageRewardData, PurchasedRewardDisplayData, RedeemRewardRequestData, RewardDisplayData } from '../../../src/types/rewards';
import { District, School, User, UserData } from '../../../src/types/user';
import { SpotlightData } from '../../../src/types/spotlight';
import { GetTeacherDataDashboard, GetAdminDataDashboard } from '../../../src/types/dataDashboard';
import { SchoolYear } from '../../../src/util/dates';
import { generateFileNameWithDate } from '../util/images';
import { RecognitionType, SchoolOrDistrictReward } from '../util/type';

export interface MainServiceApi {
    getRecognitionPaginated: (options: {
        recognitionType: RecognitionType,
        schoolId?: number,
        districtId?: number,
        userId: number,
        page: number,
        pageSize: number,
        token: string,
        startDate: Date,
        endDate?: Date
    }) => Promise<{
        recognition: RecognitionData[],
        totalPages: number,
        currentPage: number,
        count: number
    }>;
    createRecognition: (newRecognition: CreateRecognition, token: string) => Promise<CreateRecognitionResult>;
    removeRecognition: (recognitionId: string, token: string) => Promise<void>;
    getTokenTypes: (schoolId: number, token: string) => Promise<TokenTypeDisplayData[]>;
    getDistrictTokenTypes: (districtId: number, token: string) => Promise<TokenTypeDisplayData[]>;
    getSchoolStaff: (schoolId: number, token: string) => Promise<User[]>;
    getSchoolStudents: (schoolId: number, token: string) => Promise<User[]>;
    getAvailableRewardsForSchool: (schoolId: number, token: string) => Promise<RewardDisplayData[]>
    getPurchasedRewardsForUser: (userId: number, schoolId: number, token: string) => Promise<PurchasedRewardDisplayData[]>;
    redeemReward: (data: RedeemRewardRequestData, token: string) => Promise<PurchasedRewardDisplayData>;
    getTeacherDataDashboard: (data: {
        userId: number, 
        schoolId: number, 
        includeDistrictData: boolean,
        startDate: Date,
        endDate?: Date
        token: string
    }) => Promise<GetTeacherDataDashboard>;
    getAdminDataDashboard: (data: {
        userId: number, 
        schoolId: number, 
        startDate: Date,
        endDate?: Date,
        token: string
    }) => Promise<GetAdminDataDashboard>;
    getRecognitionNotificationsForUser: (userId: number, schoolId: number, token: string, lastLoginDate?: Date) => Promise<RecognitionNotification[]>;
    getSpotlightDataForSchool: (data: {schoolId: number, startDate: Date, token: string}) => Promise<SpotlightData>;
    getSpotlightDataForDistrict: (data: {districtId: number, startDate: Date, token: string}) => Promise<SpotlightData>;
    getDistrictSchools: (districtId: number, token: string) => Promise<School[]>;
    getSchool: (schoolId: number, token: string) => Promise<School>;
    getDistrict: (districtId: number, token: string) => Promise<District>;
    getSchoolYearsList: (data: { schoolId: number, token: string}) => Promise<SchoolYear[]>;
    getDistrictUsers: (data: {districtId: number, token: string}) => Promise<UserData[]>;
    getDeactivatedDistrictUsers: (data: {districtId: number, token: string}) => Promise<UserData[]>;
    getDistrictRewards: (data: {districtId: number, token: string})=> Promise<GetManageRewardsData>;
    createReward: (data: {
        displayName: string,
        description?: string,
        schoolId: number,
        districtId: number,
        schoolOrDistrictReward: SchoolOrDistrictReward,
        cost: number,
        limit?: number,
        ownerUserId: number,
        redeemInstructions?: string,
        imagePath?: string
    }, token: string) => Promise<ManageRewardData>;
    updateReward: (rewardId: number, data: {
        displayName: string,
        description?: string,
        schoolId: number,
        districtId: number,
        schoolOrDistrictReward: SchoolOrDistrictReward,
        cost: number,
        limit?: number,
        ownerUserId: number,
        redeemInstructions?: string,
        imagePath?: string
    }, token: string) => Promise<ManageRewardData>;
    getDefaultRewardImage: (token: string) => Promise<{imagePath: string, imageUrl: string}>;
    deactivateReward: (rewardId: number, token: string) => Promise<void>;
    uploadRewardImage: (data: {file: File, districtId: number, token: string}) => Promise<string>;
}

export interface MainServiceApiFactory {
    (): MainServiceApi;
}

export const MainServiceApi: MainServiceApiFactory = (): MainServiceApi => {
    return {
        getRecognitionPaginated: async ( options: {
            recognitionType: RecognitionType,
            schoolId?: number,
            districtId?: number,
            userId: number,
            page: number,
            pageSize: number,
            token: string,
            startDate: Date,
            endDate?: Date
        }): Promise<{
            recognition: RecognitionData[],
            totalPages: number,
            currentPage: number,
            count: number
        }> => {
            const {schoolId, districtId, userId, page, pageSize, recognitionType, token, startDate, endDate} = options;
            
            let requestUrl = `/api/v1/recognition/`;
            // Choose if we are calling the School, District, or User recognition endpoint
            if (recognitionType === RecognitionType.School) {
                // School
                requestUrl += `school?schoolid=${schoolId}`
            } else if (recognitionType === RecognitionType.District) {
                // District
                requestUrl += `district?districtid=${districtId}` 
            } else if (recognitionType === RecognitionType.Student) {
                // Student
                requestUrl += `students?schoolid=${schoolId}`
            } else {
                // User
                requestUrl += `user?userid=${userId}&schoolid=${schoolId}${recognitionType === RecognitionType.UserReceived ? '&type=received' : '&type=sent'}`;
            }
            // set the page size, start date, and end date (end date is optional)
            requestUrl += `&page=${page}&pagesize=${pageSize}&startDate=${startDate}`;
            if (endDate) {
                requestUrl += `&endDate=${endDate}`;
            }

            const response = await fetch(requestUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN') || '',
                }
            });
            if (response.ok) {
                const result: GetPaginatedRecognitionData = await response.json();
                
                return {
                    recognition: result.recognition,
                    totalPages: result.totalPages,
                    currentPage: result.page,
                    count: result.count
                }
            } else {
                throw new Error(`Error retrieving recognition for school. Error message: ${response.statusText}`);
            }
        },
        createRecognition: async (newRecognition: CreateRecognition, token: string): Promise<CreateRecognitionResult> => {
            const response = await fetch('/api/v1/recognition', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN') || '',
                },
                body: JSON.stringify(newRecognition)
            });
            if (response.ok) {
                const result: CreateRecognitionResult = await response.json();
                return result;
            } else {
                throw new Error(`Error creating new recognition. Error message: ${response.statusText}`)
            }
        },
        removeRecognition: async (recognitionId: string, token: string): Promise<void> => {
            const response = await fetch('/api/v1/recognition?remove=true', {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN') || '',
                },
                body: JSON.stringify({
                    id: recognitionId
                })
            });
            if (response.ok) {
                return;
            } else {
                throw new Error(`Error removing recognition id ${recognitionId}. Error message: ${response.statusText}`)
            }
        },
        getTokenTypes: async (schoolId: number, token: string): Promise<TokenTypeDisplayData[]> => {
            const response = await fetch(`/api/v1/tokentypes?schoolid=${schoolId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN') || '',
                }
            });
            if (response.ok) {
                return response.json();
            } else {
                throw new Error(`Error retrieving token types for school. Error message: ${response.statusText}`);
            }
        },
        getDistrictTokenTypes: async (districtId: number, token: string): Promise<TokenTypeDisplayData[]> => {
            const response = await fetch(`/api/v1/districttokentypes?districtid=${districtId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN') || '',
                }
            });
            if (response.ok) {
                return response.json();
            } else {
                throw new Error(`Error retrieving token types for district. Error message: ${response.statusText}`);
            }
        },
        getSchoolStaff: async (schoolId: number, token: string): Promise<User[]> => {
            /* Note - this endpoint returns the list of school staff that is allowed to receive recognition - shouldn't be used for other purposes */
            const response = await fetch(`/api/v1/school/${schoolId}/schoolstaff`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN') || '',
                }
            });
            if (response.ok) {
                return response.json();
            } else {
                throw new Error(`Error retrieving staff list for school. Error message: ${response.statusText}`);
            }
        },
        getSchoolStudents: async (schoolId: number, token: string): Promise<User[]> => {
            /* Note - this endpoint returns the list of students that are allowed to receive recognition - shouldn't be used for other purposes */
            /* It won't return deactivated students */
            const response = await fetch(`/api/v1/school/${schoolId}/students`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN') || '',
                }
            });
            if (response.ok) {
                return response.json();
            } else {
                throw new Error(`Error retrieving student list for school. Error message: ${response.statusText}`);
            }
        },
        getAvailableRewardsForSchool: async (schoolId: number, token: string): Promise<RewardDisplayData[]> => {
            const response = await fetch(`/api/v1/rewards/school?schoolid=${schoolId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN') || '',
                }
            });
            if (response.ok) {
                return response.json();
            } else {
                throw new Error(`Error retrieving rewards for school. Error message: ${response.statusText}`);
            }
        },
        getPurchasedRewardsForUser: async (userId: number, schoolId: number, token: string): Promise<PurchasedRewardDisplayData[]> => {
            const response = await fetch(`/api/v1/rewards/user?userid=${userId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'school-id': schoolId.toString(),
                    'Authorization': `Bearer ${token}`,
                    'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN') || '',
                }
            });
            if (response.ok) {
                return response.json();
            } else {
                throw new Error(`Error retrieving purchased rewards for user. Error message: ${response.statusText}`);
            }
        },
        redeemReward: async (data: RedeemRewardRequestData, token: string): Promise<PurchasedRewardDisplayData> => {
            const response = await fetch(`/api/v1/rewards/redeem`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN') || '',
                },
                body: JSON.stringify(data)
            });
            if (response.ok) {
                return response.json();
            } else {
                throw new Error(`Error redeeming reward for user. Error message: ${response.statusText}`);
            }
        },
        getTeacherDataDashboard: async (data: {
            userId: number,
            schoolId: number,
            includeDistrictData: boolean,
            startDate: Date,
            endDate?: Date,
            token: string
        }): Promise<GetTeacherDataDashboard> => {
            const {
                userId, 
                schoolId, 
                includeDistrictData, 
                token,
                startDate,
                endDate
            } = data;
            let requestUrl = `/api/v1/datadashboard/teacher/${userId}?includeDistrict=${includeDistrictData ? 'true' : 'false'}&startDate=${startDate}`;
            if (endDate) {
                requestUrl += `&endDate=${endDate}`
            }
            const response = await fetch(requestUrl, {
                method: 'GET',
                headers: {
                    'school-id' : schoolId.toString(),
                    'Authorization': `Bearer ${token}`,
                    'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN') || '',
                },
            });
            if (response.ok) {
                return response.json();
            } else {
                throw new Error(`Error retrieving data for teacher data dashboard. Error message: ${response.statusText}`)
            }
        },
        getAdminDataDashboard: async (data: {
            userId: number, 
            schoolId: number, 
            startDate: Date,
            endDate?: Date,
            token: string
        }): Promise<GetAdminDataDashboard> => {
            const { userId, schoolId, startDate, endDate, token} = data;
            
            let requestUrl = `/api/v1/datadashboard/admin/${userId}?startDate=${startDate}`;
            if (endDate) {
                requestUrl += `&endDate=${endDate}`;
            }
            const response = await fetch(requestUrl, {
                method: 'GET',
                headers: {
                    'school-id': schoolId.toString(),
                    'Authorization': `Bearer ${token}`,
                    'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN') || '',
                },
            });
            if (response.ok) {
                return response.json();
            } else {
                throw new Error(`Error retrieving data for admin data dashboard. Error message: ${response.statusText}`)
            }
        },
        getRecognitionNotificationsForUser: async (
            userId: number,
            schoolId: number,
            token: string,
            lastLoginDate?: Date
        ): Promise<RecognitionNotification[]> => {
            const response = await fetch(`/api/v1/recognition/notifications?userid=${userId}&schoolid=${schoolId}&lastlogindate=${lastLoginDate?.toString()}`,{
                method: 'GET',
                headers: {
                    'school-id': schoolId.toString(),
                    'Authorization': `Bearer ${token}`,
                    'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN') || '',
                }
            });

            if (response.ok) {
                return response.json();
            } else {
                throw new Error(`Error retrieiving recognition notifications for user. Error message: ${response.statusText}`);
            }
        },
        getSpotlightDataForSchool: async (data: {
            schoolId: number,
            startDate: Date,
            token: string
        }): Promise<SpotlightData> => {
            const { schoolId, startDate, token} = data;
            const response = await fetch(`/api/v1/spotlight/school/${schoolId}?startDate=${startDate}`, {
                method: 'GET',
                headers: {
                    'school-id': schoolId.toString(),
                    'Authorization': `Bearer ${token}`,
                    'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN') || '',
                }
            });

            if (response.ok) {
                return response.json();
            } else {
                throw new Error(`Error retrieving spotlight data. Error message: ${response.statusText}`)
            }
        },
        getSpotlightDataForDistrict: async (data: {
            districtId: number,
            startDate: Date,
            token: string
        }): Promise<SpotlightData> => {
            const { districtId, startDate, token} = data;
            const response = await fetch(`/api/v1/spotlight/district/${districtId}?startDate=${startDate}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN') || '',
                }
            });

            if (response.ok) {
                return response.json();
            } else {
                throw new Error(`Error retrieving spotlight data. Error message: ${response.statusText}`)
            }
        },
        getDistrictSchools: async (districtId: number, token: string): Promise<School[]> => {
            const response = await fetch(`/api/v1/district/${districtId}/schools`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN') || '',
                }
            });

            if (response.ok) {
                return response.json();
            } else {
                throw new Error(`Error retrieving schools for district. Error message: ${response.statusText}`)
            }
        },
        getSchool: async (schoolId: number, token: string): Promise<School> => {
            const response = await fetch(`/api/v1/school/${schoolId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN') || '',
                }
            });
            if (response.ok) {
                return response.json();   
            } else {
                throw new Error(`Error retrieving school data for school id: ${schoolId}. Error message: ${response.statusText}`);
            }
        },
        getDistrict: async (districtId: number, token: string): Promise<District> => {
            const response = await fetch(`/api/v1/district/${districtId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN') || '',
                }
            });
            if (response.ok) {
                return response.json();   
            } else {
                throw new Error(`Error retrieving school data for district id: ${districtId}. Error message: ${response.statusText}`);
            }
        },
        getSchoolYearsList: async (data: { schoolId: number, token: string}): Promise<SchoolYear[]> => {
            const { schoolId, token} = data;
            const response = await fetch(`/api/v1/school/${schoolId}/schoolYears`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN') || '',
                }
            });

            if (response.ok) {
                return response.json();
            } else {
                throw new Error(`Error retrieving list of school years. Error message: ${response.statusText}`)
            }
        },
        getDistrictUsers: async (data: {districtId: number, token: string}): Promise<UserData[]>=> {
            const { districtId, token } = data;
            const response = await fetch(`/api/v1/district/${districtId}/users`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN') || '',
                }
            });

            if (response.ok) {
                return response.json();
            } else {
                throw new Error(`Error retrieving list of district users. Error message: ${response.statusText}`)
            }
        },
        getDeactivatedDistrictUsers: async (data: {districtId: number, token: string}): Promise<UserData[]> => {
            const { districtId, token } = data;
            const response = await fetch(`/api/v1/district/${districtId}/users/deleted`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN') || '',
                }
            });

            if (response.ok) {
                return response.json();
            } else {
                throw new Error(`Error retrieving list of deactivated district users. Error message: ${response.statusText}`)
            }
        },
        getDistrictRewards: async (data: {districtId: number, token: string}): Promise<GetManageRewardsData> => {
            const { districtId, token } = data;
            const response = await fetch(`/api/v1/district/${districtId}/admin/rewards`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN') || '',
                }
            });

            if (response.ok) {
                return response.json();
            } else {
                throw new Error(`Error retrieving district rewards data for admin. Error message: ${response.statusText}`)
            }
        },
        createReward: async (data: { 
            displayName: string,
            description?: string,
            schoolId: number,
            cost: number,
            limit?: number,
            ownerUserId: number,
            redeemInstructions?: string,
            imagePath?: string
        }, token: string): Promise<ManageRewardData> => {
            const response = await fetch(`/api/v1/reward`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN') || '',
                },
                body: JSON.stringify(data)
            });

            if (response.ok) {
                return response.json();
            } else {
                throw new Error(`Error creating reward. Error message: ${response.statusText}`)
            } 
        },
        updateReward: async (rewardId: number, data: { 
            displayName: string,
            description?: string,
            schoolId: number,
            cost: number,
            limit?: number,
            ownerUserId: number,
            redeemInstructions?: string,
            imagePath?: string
        }, token: string): Promise<ManageRewardData> => {
            const response = await fetch(`/api/v1/reward/${rewardId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN') || '',
                },
                body: JSON.stringify(data)
            });

            if (response.ok) {
                return response.json();
            } else {
                throw new Error(`Error creating reward. Error message: ${response.statusText}`)
            } 
        },
        getDefaultRewardImage: async (token: string): Promise<{imagePath: string, imageUrl: string}> => {
            const response = await fetch('/api/v1/defaultrewardimage', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN') || '',
                }
            });

            if (response.ok) {
                return response.json();
            } else {
                throw new Error(`Error getting default reward image. Error message: ${response.statusText}`)
            } 
        },
        deactivateReward: async (rewardId: number, token: string): Promise<void> => {
            const response = await fetch(`/api/v1/reward/${rewardId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN') || '',
                },
                body: JSON.stringify({
                    deactivate: true
                })
            });

            if (response.ok) {
                return;
            } else {
                throw new Error(`Error deactivating reward id: ${rewardId}. Error message: ${response.statusText}`);
            }
        },
        uploadRewardImage: async (data: {file: File, districtId: number, token: string}): Promise<string> => {
            const newFileName = generateFileNameWithDate(`reward_districtId_${data.districtId}`, data.file.name); // just made the prefix 'reward'... could be anything
            const newFilePath = `rewards/${newFileName}`;

            // First get the signed s3 upload url
            const getSignedUrlResponse = await fetch(`/api/v1/image`, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${data.token}`,
                    'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN') || '',
                },
                body: JSON.stringify({
                    imageName: newFilePath
                })
            });

            if (!getSignedUrlResponse.ok) {
                throw new Error('Error retrieving signed url to upload photo.');
            }
            const result = await getSignedUrlResponse.json();
            const imageUploadUrl = result.imageUploadSignedUrl;
            if (!imageUploadUrl) {
                throw new Error('Error retrieving signed url to upload photo. No signed url returned.')
            }
            
            // Upload to S3
            const s3UploadResponse = await fetch(imageUploadUrl, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'image/*',
                },
                body: data.file
            });

            // TO DO - if it failed because it expired, get another url
            if (!s3UploadResponse.ok) {
                throw new Error('Error uploading reward photo to S3: ' + s3UploadResponse.statusText)
            }

            return newFilePath;
        }
    }
}